<template>
  <div class="wrapper">
    <div class="page_container">
      <BackComponent :title="$t('transactionHistory')" />
    </div>

    <div class="filter">
      <button @click="handleAction('by-type')">
        <span> {{ selectedType }}</span>
        <span><CaretDown /></span>
      </button>
      <button class="filter-btn-2" @click="handleAction('by-time')">
        <div>
          <span><Calendar /></span>
          <span>{{ selectedTime }}</span>
        </div>
        <span><CaretDown /></span>
      </button>
    </div>
    <div class="load page_container" v-if="loading">
      <TransactionLoader :count="10" />
    </div>
    <section class="profile_links">
      <div
        v-if="
          (!loading && !transaction) || (!loading && transaction.length < 1)
        "
        class="no_data"
      >
        <NoData
          :title="$t('noTransaction')"
          :description="$t('noDataForFilter')"
        />
      </div>
      <div v-if="!loading && transaction && transaction.length > 0">
        <div
          v-for="(item, index) in transaction"
          :key="index"
          class="transaction_wrap"
        >
          <div>
            <div class="box_one">
              <span v-if="item.transaction_type === 'Credit'">
                <CreditIcon />
              </span>
              <span v-else>
                <DebitIcon />
              </span>
              <div class="description">
                <h3>{{ item.description }}</h3>
                <p>
                  <!-- ID: {{ item.reference_id }} • -->
                  <!-- {{ formattedDate(item.created) }} -->
                  {{ item.created }}
                </p>
              </div>
            </div>
            <div class="box_two">
              <h3>
                {{ item.transaction_type === "Credit" ? "+" : "-" }}
                {{ fiatCurrency }}
                {{ item.amount }}
              </h3>
              <p>
                {{ fiatCurrency }} {{ item.balance + item.winning_balance }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Modal :isOpen="isModalOpen" @onClose="closeModal" maxWidth="600px">
      <div>
        <h3>{{ $t("customFilter") }}</h3>
        <button @click="closeModal">{{ $t("close") }}</button>
      </div>
    </Modal>

    <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
      <div class="learn_more">
        <div v-if="actionType === 'by-type'" class="filter_contents">
          <p>
            <span>{{ $t("filterByType") }}</span>
            <span @click="closeSlideUp"><CancelIcon /></span>
          </p>
          <div
            v-for="item in transactionType"
            :key="item"
            class="item"
            @click="handleSelectedType(item)"
          >
            <span
              ><span>{{ item.name }}</span>
              <span v-if="selectedType === item.name">
                <TickCircle />
              </span>
              <span v-else> </span>
            </span>
          </div>
        </div>
        <div v-if="actionType === 'by-time'" class="filter_contents">
          <p>
            <span>{{ $t("filterByDate") }}</span>
            <span @click="closeSlideUp"><CancelIcon /></span>
          </p>
          <div v-if="timeCustom" class="custom-time">
            <div>
              <div class="start-date">
                <label for="">{{ $t("from") }}</label>
                <input
                  type="date"
                  v-model="selectedStartDate"
                  @change="handleDateChange(selectedStartDate, 'start')"
                  class="date-picker"
                  placeholder="Date"
                />
              </div>
              <div class="start-date">
                <label for="">{{ $t("to") }}</label>
                <input
                  type="date"
                  v-model="selectedEndDate"
                  @change="handleDateChange(selectedEndDate, 'end')"
                  class="date-picker"
                  placeholder="Date"
                />
              </div>
            </div>
            <ChopbetButton @click="handleSubmitDate" variant="primary">{{
              $t("done")
            }}</ChopbetButton>
          </div>
          <div
            v-else
            v-for="item in timeFilter"
            :key="item.id"
            class="item"
            @click="handleSelectedDate(item)"
          >
            <span
              ><span>{{ item.name }}</span>
              <span v-if="selectedTime === item.name"> <TickCircle /> </span
            ></span>
          </div>
        </div>
      </div>
    </ChopbetSlideUp>
    <div v-if="transaction && transaction.length > 0">
      <scroll-loader
        v-show="transaction.length > 0"
        :loader-method="loadOnScroll"
        :loader-disable="is_busy || page >= last_page"
      >
        <div></div>
      </scroll-loader>
    </div>
  </div>
</template>

<style scoped src="./index.css"></style>

<script>
import BackComponent from "../../components/ui/BackComponent.vue";
import CreditIcon from "../../components/icons/CreditIcon.vue";
import DebitIcon from "../../components/icons/DebitIcon.vue";
import Modal from "../../components/ui/Modal.vue";
import CaretDown from "../../components/icons/CaretDown.vue";
import Calendar from "../../components/icons/Calendar.vue";
import ChopbetSlideUp from "../../components/ui/ChopbetSlideUp.vue";
import TransactionLoader from "./TransactionLoader.vue";
import ChopbetButton from "../../components/ui/ChopbetButton.vue";
import CancelIcon from "../../components/icons/CancelIcon.vue";
import NoData from "../../components/ui/NoData.vue";
import ScrollLoader from "vue-scroll-loader";
import Vue from "vue";
import { fetchTransactions } from "../../actions/transactions";
import TickCircle from "../../components/icons/TickCircle.vue";
Vue.use(ScrollLoader);

export default {
  name: "Transactions",
  components: {
    TransactionLoader,
    BackComponent,
    CreditIcon,
    DebitIcon,
    Modal,
    TickCircle,
    // Dropdown,
    CancelIcon,
    ChopbetSlideUp,
    CaretDown,
    Calendar,
    NoData,
    ChopbetButton,
  },
  props: {
    // actionType: {
    //   type: String,
    //   required: false,
    // },
  },
  data: function () {
    const today = new Date().toISOString().substr(0, 10);
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      myProfile: this.getProfile(),
      isSlideUpOpen: false,
      loading: true,
      page: 1,
      per_page: 10,
      last_page: 1,
      start: "",
      end: "",
      sort: "created|desc",
      transaction_type: 0,
      reference_id: 0,
      actionType: "by-time",
      timeCustom: false,
      selectedTime: this.$t("showAll"),
      selectedStartDate: today,
      selectedEndDate: today,
      currency: process.env.VUE_APP_CURRENCY,
      isModalOpen: false,
      selectedCustomDate: "",
      selectedCustomDate2: "",
      is_busy: false,
      transaction: [],
      selectedValue: this.$t("showAll"),
      selectedDate: this.$t("showAll"),
      selectedType: this.$t("allTransactions"),
      timeFilter: [
        { id: 1, name: this.$t("showAll"), value: "Show All" },
        { id: 2, name: this.$t("today"), value: "Today" },
        { id: 3, name: this.$t("thisWeek"), value: "This Week" },
        { id: 4, name: this.$t("thisMonth"), value: "This Month" },
        { id: 5, name: this.$t("customDate"), value: "Custom Date" },
      ],
      transactionType: [
        {
          id: 1,
          name: this.$t("allTransactions"),
          value: "All transactions",
          reference_id: 0,
        },
        { id: 2, name: this.$t("deposit"), value: "Deposit", reference_id: 11 },
        {
          id: 3,
          name: this.$t("withdrawal"),
          value: "Withdrawal",
          reference_id: 9,
        },
        {
          id: 4,
          name: this.$t("winnings"),
          value: "Winnings",
          reference_id: 6,
        },
        { id: 5, name: this.$t("bets"), value: "Bets", reference_id: 1 },
        {
          id: 6,
          name: this.$t("freeBet"),
          value: "Free Bet",
          reference_id: 14,
        },
        { id: 7, name: this.$t("bonus"), value: "Bonus", reference_id: 4 },
        {
          id: 8,
          name: this.$t("credit"),
          value: "Credit",
          transaction_type: 1,
        },
        { id: 9, name: this.$t("debit"), value: "Debit", transaction_type: 2 },
      ],
      hasMore: true,
    };
  },
  methods: {
    truncateMiddle(description, maxLength) {
      if (description.length <= maxLength) {
        return description;
      }

      const halfLength = Math.floor(maxLength / 2);
      const start = description.slice(0, halfLength);
      const end = description.slice(-halfLength);

      return `${start}...${end}`;
    },
    loadOnScroll: function () {
      var vm = this;
      if (vm.transaction) {
        vm.myTransactions();
      }
    },
    closeSlideUp() {
      this.isSlideUpOpen = false;
      this.timeCustom = false;
    },
    handleDateChange(value, type) {
      if (type === "start") {
        this.selectedStartDate = value;
      } else {
        this.selectedEndDate = value;
      }
    },
    handleSubmitDate() {
      if (this.selectedStartDate && this.selectedEndDate) {
        this.start = this.selectedStartDate;
        this.end = this.selectedEndDate;
        this.closeSlideUp();
        this.selectedTime = "Custom Date";
        this.page = 1;
      }
    },
    handleSelectedDate(time) {
      const today = new Date();
      let startDate, endDate;

      if (time.value === "Custom Date") {
        this.timeCustom = true;
        return;
      }

      if (time.value === "Show All") {
        this.start = "";
        this.end = "";
      } else if (time.value === "Today") {
        startDate = today;
        endDate = today;
      } else if (time.value === "This Week") {
        const firstDayOfWeek = new Date(
          today.setDate(today.getDate() - today.getDay())
        );
        startDate = new Date(firstDayOfWeek);
        endDate = new Date();
      } else if (time.value === "This Month") {
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date();
      }
      this.start = startDate ? startDate.toISOString().split("T")[0] : "";
      this.end = endDate ? endDate.toISOString().split("T")[0] : "";
      this.selectedTime = time.name;

      this.closeSlideUp();
    },
    handleSelectedType(type) {
      // this.page = 1;
      if (type.reference_id) {
        this.reference_id = type.reference_id;
        this.transaction_type = 0;
      } else {
        this.transaction_type = type.transaction_type;
        this.reference_id = 0;
      }
      this.selectedType = type.name;
      this.closeSlideUp();
    },
    handleAction(type) {
      this.actionType = type;
      this.isSlideUpOpen = true;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleDropdownChange(value) {
      this.selectedValue = value;
    },
    async myTransactions() {
      const vm = this;
      var m = this.getProfile();
      var p = m.a;
      if (vm.is_busy) return;

      if (parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }
      vm.is_busy = true;
      if (!p) {
        this.$router.push({ name: "login", params: {} });
        return;
      }

      try {
        const transactions = await fetchTransactions({
          page: this.page,
          per_page: this.per_page,
          start: this.start,
          end: this.end,
          sort: this.sort,
          transaction_type: this.transaction_type,
          reference_id: this.reference_id,
          apiKey: this.getAuth(),
        });
        if (transactions?.data === null) {
          vm.transaction = null;
        }
        if (transactions && Array.isArray(transactions.data)) {
          if (transactions?.data?.length > 0) {
            vm.last_page = transactions.last_page;
            if (parseInt(vm.page) === 1) {
              vm.transaction = [];
            }
            vm.transaction.push(...transactions.data);
            vm.page = parseInt(vm.page) + 1;
          } else {
            vm.is_busy = false;
            return;
          }
        } else {
          vm.transaction = [];
          vm.is_busy = false;
          return;
        }
        vm.is_busy = false;
      } catch (error) {
        console.log(error, "eroeoroeoroeoroeoro");

        this.loading = false;
        // if ([401, 400, 428].includes(error?.response?.status)) {
        //   this.setError(`${this.$t("sessionExpired")}`);
        //   this.logout();
        //   return;
        // }
        // this.setError(error?.response?.data?.message);
        this.handleFetchError(error);
      } finally {
        this.loading = false;
      }
    },
    handleFetchError(error) {
      this.loadingGames = false;
      if (error.response) {
        if (parseInt(error.response.data.status) === 401) {
          this.setError(`${this.$t("sessionExpired")}`);
          this.logout();
          return;
        }
        this.setError(error.response.data.error_message);
      }
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
      return date.toISOString().split("T")[0];
    },
  },
  computed: {
    // profile: function () {
    //   return this.getProfile();
    // },
    profile: function () {
      return this.myProfile;
    },
  },
  watch: {
    transaction_type() {
      this.page = 1;
      this.loading = true;
      this.myTransactions();
    },
    reference_id() {
      this.loading = true;
      this.page = 1;
      this.myTransactions();
    },
    start() {
      this.loading = true;
      this.page = 1;
      this.myTransactions();
    },
    end() {
      this.loading = true;
      this.page = 1;
      this.myTransactions();
    },
  },

  mounted() {
    if (!this.myProfile) {
      this.setError("Login", this.$t("pleaseLoginProceed"));
      this.$router.push({ name: "login", params: {} });
      return;
    }
    this.myTransactions();
  },
};
</script>
